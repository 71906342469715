import React, { useState, useRef, useEffect } from 'react';
import Video from './nafta_02.mp4';
import PlayIcon from '../../play.svg';
import PauseIcon from '../../pause.svg';
import FullscreenIcon from '../../fullscreen.svg';
import ExitFullscreenIcon from '../../fullscreen.svg';

const VideoPlayer = ({ onFullscreenToggle }) => {
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(false); // Initially set to false

  // Function to detect mobile device
  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (!isDragging && videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const calculateProgress = (e) => {
    const progressBar = progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const percentage = clickPosition / rect.width;
    return percentage * videoRef.current.duration;
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    videoRef.current.pause();
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newTime = calculateProgress(e);
      setProgress((newTime / videoRef.current.duration) * 100);
      videoRef.current.currentTime = newTime;
    }
  };

  const handleMouseUp = (e) => {
    if (isDragging) {
      setIsDragging(false);
      const newTime = calculateProgress(e);
      videoRef.current.currentTime = newTime;
      if (isPlaying) {
        videoRef.current.play();
      }
    }
  };

  const toggleFullscreen = () => {
    const video = videoRef.current;
    if (!document.fullscreenElement) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
      setIsFullscreen(true);
      if (onFullscreenToggle) onFullscreenToggle(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
      if (onFullscreenToggle) onFullscreenToggle(false);
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === 'Space' || e.key === ' ') {
        e.preventDefault();
        togglePlay();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Update showControls based on device type
    setShowControls(isMobileDevice());

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [isPlaying, isDragging]); 

  return (
    <div className="video-player-container">
      <video ref={videoRef} controls={showControls}>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!showControls && (
        <div className="video-controls">
                    <div className="play-button-container" onClick={togglePlay}>
            <img src={isPlaying ? PauseIcon : PlayIcon} alt={isPlaying ? 'Pause' : 'Play'} />
          </div>
          <div className="progress-bar-container">
            <div 
              className="progress-bar" 
              ref={progressBarRef} 
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={isDragging ? handleMouseUp : undefined}
            >
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
          <div className="fullscreen-button-container" onClick={toggleFullscreen}>
        <img src={isFullscreen ? ExitFullscreenIcon : FullscreenIcon} alt={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'} />
      </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;