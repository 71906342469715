import React, { useEffect, useState } from 'react';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom'; // Import useLocation
import dmcover1 from '../Media/Projects/Nafta/nafta_cover_01.jpg';
import dmcover2 from '../Media/Projects/Nafta/nafta_cover_02.jpg';
import "slick-carousel/slick/slick.css";
import "../App.css";
import VideoPlayer from '../Media/Projects/Nafta/NaftaPlayer_01.js';
import VideoPlayer2 from '../Media/Projects/Nafta/NaftaPlayer_02.js';
import VideoPlayer3 from './VideoPlayer.js';
import VideoPlayer4 from './VideoPlayer.js';
import Next from '../Media/next.svg';
import Prev from '../Media/prev.svg';
import { Closeicon } from '../Media/Media.js';



const Modal = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Use useLocation here
    const [selectedItem, setSelectedItem] = useState(null);

    const handleSelectPrev = () => {
        navigate('/Republic');
    };

    const handleSelectNext = () => {
        navigate('/Eniteo');
    };

    const handleSelect = (itemId) => {
        setSelectedItem(itemId);
        navigate(`video${itemId}`);
    };

    const closeModal = () => {
        enableScrolling();
        navigate('/');
    };

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = () => {
            window.scrollTo(x, y);
        };
    };

    const enableScrolling = () => {
        window.onscroll = null;
    };

    useEffect(() => {
        disableScrolling();

        // Extract the video number from the URL
        const pathParts = location.pathname.split('/');
        const lastPart = pathParts[pathParts.length - 1];
        if (lastPart.startsWith('video')) {
            const videoNumber = parseInt(lastPart.replace('video', ''), 10);
            setSelectedItem(videoNumber);
        } else {
            navigate('video1', { replace: true });
        }

        return () => {
            enableScrolling();
        };
    }, [navigate, location.pathname]); // Include location.pathname in dependencies


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    let routes = useRoutes([
        { path: "video1", element: <VideoPlayer videoId="video1" /> },
        { path: "video2", element: <VideoPlayer2 videoId="video2" /> }, // Correct videoId
        { path: "video3", element: <VideoPlayer3 videoId="video3" /> }, // Correct videoId
        { path: "video4", element: <VideoPlayer4 videoId="video4" /> }, // Correct videoId
      ]);

    return (
        <div id="ModalContainer">
            <div className="ModalBackdrop" onClick={closeModal} />
            <div id="ModalBody">
                <div className="ModalScroll">
                        <img
                            className="PrevIcon" src={Prev} alt="Prev" onClick={() => handleSelectPrev()}
                        />
                    </div>
                <div id="ModalBlock">
                    <div id="ModalHeader">
                        <p className="projectname">Naftagaz</p>
                        <img src={Closeicon} alt="Close" onClick={closeModal} />
                    </div>
                    <div id="ModalContent">
                        <div className="player">
                            {routes}
                        </div>
                        <div className="selector">
                            <div className={`selectorItem ${selectedItem === 1 ? 'selected' : ''}`} onClick={() => handleSelect(1)}>
                                <img src={dmcover1} alt="Image 1" />
                            </div>
                            <div className="separator"></div>
                            <div className={`selectorItem ${selectedItem === 2 ? 'selected' : ''}`} onClick={() => handleSelect(2)}>
                                <img src={dmcover2} alt="Image 1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ModalScroll">
                    <img
                        className="nextIcon" src={Next} alt="Next Icon" onClick={() => handleSelectNext()}
                     />
                </div>
            </div>
            <div className="ModalBackdrop" onClick={closeModal} />
        </div>
    );
};

export default Modal;
