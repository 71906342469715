import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Import your components
import DM from './components/Discovermarket.js';
import Era from './components/Era.js';
import Porshe from './components/Porshe.js';
import Disona from './components/Disona.js';
import Clingr from './components/Clingr.js';
import Alcon from './components/Alcon.js';
import Ava from './components/Ava.js';
import Fci from './components/Fci.js';
import Ever from './components/Ever.js';
import Republic from './components/Republic.js';
import Nafta from './components/Nafta.js';
import Eniteo from './components/Eniteo.js';
import Poklonnaya from './components/poklonnaya.js';
import Secretgarden from './components/Secretgarden.js';
import Vpr from './components/Vpr.js';
import Yes from './components/Yes.js';

// Lazy load components
const Previewvideo = lazy(() => import('./components/Preview.js'));
const Reel = lazy(() => import('./components/Reel.js'));
const Uispace = lazy(() => import('./components/Uispace.js'));
const Cases = lazy(() => import('./components/Portfolio.js'));
const About = lazy(() => import('./components/Aboutpage.js'));

function App() {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    // Check if the entered password is correct
    if (password === '123') {
      setAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          {authenticated ? (
            <>
              <Previewvideo />
              <Routes>
                <Route path="/reel" element={<Reel />} />
                <Route path="/Discovermarket/*" element={<DM />} />
                <Route path="/Era/*" element={<Era />} />
                <Route path="/Porshe*" element={<Porshe />} />
                <Route path="/Disona*" element={<Disona />} />
                <Route path="/Clingr*" element={<Clingr />} />
                <Route path="/Alcon*" element={<Alcon />} />
                <Route path="/Ava*" element={<Ava />} />
                <Route path="/Fci*" element={<Fci />} />
                <Route path="/Ever*" element={<Ever />} />
                <Route path="/Republic*" element={<Republic />} />
                <Route path="/Nafta*" element={<Nafta />} />
                <Route path="/Eniteo*" element={<Eniteo />} />
                <Route path="/poklonnaya*" element={<Poklonnaya />} />
                <Route path="/secretgarden*" element={<Secretgarden />} />
                <Route path="/Vpr*" element={<Vpr />} />
                <Route path="/Yes*" element={<Yes />} />
              </Routes>
              <Uispace />
              <Cases />
              <About />
            </>
          ) : (
            <div className="pass">
              <form onSubmit={handlePasswordSubmit}>
                <label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
